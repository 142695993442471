import React from "react";
import { CardBlueProps, CardBlue } from "../CardBlue/CardBlue";
import { get, defaultTo } from "lodash";
import { LANGUAGE_CATALOG } from "../../../infrastructure/constants/LanguageCatalog";

export interface RatesTabProps {
  rates?: any;
  ratesConfig?: any;
}

const formatNumber = (value: number | string, currency: string) => {
  if (typeof value === "number") {
    return value.toFixed(2) + " " + currency;
  }
  return value;
};
export const RatesTab: React.FC<RatesTabProps> = (props: RatesTabProps) => {
  const { rates, ratesConfig } = props;
  const currencyDefault = get(ratesConfig, "defaultCurrency", "USD");
  const ratesClone = defaultTo(rates, []).slice(0, 5);
  const pratesCards: CardBlueProps[] = ratesClone.map((rate: any) => {
    const configuration = get(rate, "configuration[0]", {});
    return {
      title: get(rate, "alias", ""),
      items: [
        {
          key: "Tipo de tarifa",
          value: get(
            LANGUAGE_CATALOG,
            `paymentType.${get(rate, "paymentType", "").trim()}`,
            ""
          ),
        },
        {
          key: "Medio de pago/Servicio",
          value: get(rate, "paymentMethod", [])
            .map((payMethod: string) =>
              get(LANGUAGE_CATALOG, `paymentMethod.${payMethod?.trim()}`, "")
            )
            .join(","),
        },
        {
          key: "Monto fijo",
          value: formatNumber(
            get(configuration, "fixedAmount", ""),
            currencyDefault
          ),
        },
        {
          key: "Monto variable",
          value: formatNumber(
            get(configuration, "variableAmount", ""),
            currencyDefault
          ),
        },
        {
          key: "Monto mínimo ",
          value: formatNumber(get(rate, "minimumAmount", ""), currencyDefault),
        },
        {
          key: "Monto maximo",
          value: formatNumber(get(rate, "maximumAmount", ""), currencyDefault),
        },
      ],
    } as CardBlueProps;
  });

  return (
    <>
      {pratesCards.length > 0 &&
        pratesCards.map((prop, index) => (
          <CardBlue {...prop} key={index + " " + prop.title} />
        ))}
    </>
  );
};
