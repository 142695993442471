import React, { useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Theme } from "@material-ui/core/styles";
import { navigateToUrl } from "single-spa";
import "./SideBar.css";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "../store/reducer";
import {
  IAppAction,
  logoutSupportSessionRequest,
  setActiveRoute,
  setHover,
  statusCodeSupport,
} from "../store/actionCreators";
import { connect } from "react-redux";
import {
  Divider,
  Grid,
  IconButton,
  SvgIcon,
  useTheme,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import {
  cloneDeep,
  defaultTo,
  find,
  get,
  isEmpty,
  orderBy,
  set,
  hasIn,
} from "lodash";
import PropTypes from "prop-types";
import CardSupport from "./CardSupport/CardSupport";
import { SessionStatusEnum } from "../shared/SessionStatusEnum";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { IKushkiMenu } from "../infrastructure/interfaces/IKushkiMenu";
import { LOCALSTORAGE } from "../infrastructure/constants/LocalStorage_Enum";
import CardActivateAccount from "./CardActivateAccount/CardActivateAccount";
import { RoleService } from "../services/RoleService";
import { environment } from "../environments/environment";
import { ROLES } from "../infrastructure/constants/Roles";
import { ComponentIdEnum } from "../infrastructure/constants/ComponentIdEnum";
import { SessionService } from "../services/SessionService";

const drawerWidth = 270;

const styles = (theme: Theme) => ({
  listControlRoles: {
    paddingTop: 30,
    paddingBottom: 2,
  },
  listSubListMenu: {
    padding: 0,
  },
  listItemText: {
    color: theme.palette.primary.main,
    fontSize: 16,
    letterSpacing: 0.2,
  },
  listItemImage: {
    margin: theme.spacing(1),
    top: ".155em",
    position: "relative" as
      | "-moz-initial"
      | "inherit"
      | "initial"
      | "revert"
      | "unset"
      | "fixed"
      | "-webkit-sticky"
      | "absolute"
      | "relative"
      | "static"
      | "sticky"
      | undefined,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  collapseMenu: {
    paddingLeft: 70,
  },
  collapseMenuList: {
    padding: 0,
  },
});

interface IAppFunctionsProps {
  setActiveRoute: (path: string) => void;
  getStatusSession: (payload: string) => void;
  logoutSupportSessionRequest: (supportClose: unknown) => void;
  setHover: (link: string | null) => void;
}

const verifyShowCard = () => {
  const isProduction: boolean = environment.envName === "primary";
  return (
    RoleService.validateRolUser(ROLES[2]) &&
    RoleService.validateCountry("EEUU") &&
    !isProduction
  );
};

const SideBar: React.FC<IAppState & IAppFunctionsProps> = (
  props: IAppState & IAppFunctionsProps
) => {
  const theme = useTheme();
  const inlineStyles = styles(theme);
  const [open, setOpen] = React.useState(true);
  const [viewCard, setViewCard] = React.useState(false);
  const [support, setSupport] = React.useState(false);
  const [accessCode, setAccessCode] = React.useState<string>("");
  const [openLogoutConfirmModal, setOpenLogoutConfirmModal] =
    useState<boolean>(false);
  const handleDrawerOpen = () => {
    setOpen(!open);
    if (open) setViewCard(verifyShowCard());
    else setViewCard(!open);
  };
  const dataRoleControl = orderBy(
    get(props, "roleInfo.kushkiMenu", []),
    "priority",
    "asc"
  ).filter((item: any) => item.componentId === ComponentIdEnum.ROLES_CONTROL);

  useEffect(() => {
    const openListDefault = setOpenListValues();
    setOpenList(openListDefault);
  }, [props.roleInfo]);

  const setOpenListValues = () => {
    const openListDefault = {};
    props.roleInfo?.kushkiMenu.map((kushkiMenu) => {
      set(openListDefault, kushkiMenu.link, false);
    });
    return openListDefault;
  };

  const handleClick = (url: string) => {
    handleSelectedMenu(url);
    navigateToUrl(url);
    if (url.includes("reports") && props.roleInfo?.isAdmin === true)
      window.location.reload(false);
  };
  const statusSession: string[] = [
    SessionStatusEnum.COMPLETED,
    SessionStatusEnum.CLOSE,
  ];

  useEffect(() => {
    if (props.statusSession !== undefined) {
      setAccessCode(props.statusSession.accessCode);
      setSupport(!statusSession.includes(props.statusSession.status));
      if (
        props.statusSession.status !== SessionStatusEnum.ACTIVE &&
        props.statusSession.status !== SessionStatusEnum.CREATED
      ) {
        localStorage.removeItem("accessCodeSupport");
      }
    }
  }, [props.statusSession]);

  useEffect(() => {
    window.addEventListener(
      "build",
      function (e) {
        if (localStorage.accessCodeSupport) {
          const code: string = defaultTo(
            localStorage.getItem("accessCodeSupport"),
            ""
          );
          setAccessCode(code);
          props.getStatusSession(code);
        }
      },
      false
    );
  }, []);

  useEffect(() => {
    if (RoleService.hasMerchantBasicInfo()) {
      setViewCard(verifyShowCard());
    }
  }, [props.merchant]);

  const handleCloseLogoutConfirmModal = () => {
    setOpenLogoutConfirmModal(false);
  };
  const handleAcceptLogoutConfirmModal = () => {
    setOpenLogoutConfirmModal(false);
    props.logoutSupportSessionRequest({
      accessCode,
      isCompleted: false,
      messageCloseSession: "Sessión cerrada por parte de Comercio",
    });
  };
  const handleOpenModal = () => {
    setOpenLogoutConfirmModal(true);
  };

  const handleSelectedMenu = (basePath: string) => {
    props.setActiveRoute(basePath);
  };

  const getDrawerClass = () => {
    return open ? inlineStyles.drawerOpen : inlineStyles.drawerClose;
  };

  const [openList, setOpenList] = React.useState<any>({});
  const [menuSelected, setMenuSelected] = React.useState("/main");

  const handleListClick = (link: string) => {
    let currentOpenList = cloneDeep(openList);
    if (currentOpenList[link] === true) {
      Object.keys(currentOpenList).forEach((ruta) => {
        set(currentOpenList, ruta, false);
      });
    } else {
      Object.keys(currentOpenList).forEach((ruta) => {
        set(currentOpenList, ruta, false);
      });
      currentOpenList = set(currentOpenList, link, true);
    }
    setOpenList(currentOpenList);
    if (open) {
      setMenuSelected(link);
    } else {
      setOpen(true);
    }
  };

  const filterModulesBlocked = (menu: IKushkiMenu) => {
    const merchantBlocked = find(environment.merchantsBlockedNavigation, {
      merchantId: localStorage.getItem(LOCALSTORAGE.MERCHANT_ID) ?? "",
    });

    return !merchantBlocked?.modules?.includes(menu.title) ?? true;
  };

  const filterOnlyAllowedMerchants = (list: IKushkiMenu): boolean => {
    return (
      list.allowedMerchants === undefined ||
      SessionService.includeMerchant(list.allowedMerchants || [])
    );
  };

  return (
    <div className="custom-drawer">
      <Drawer
        variant="permanent"
        style={{ ...inlineStyles.drawer, ...getDrawerClass() }}
        classes={{
          paper: open ? "custom-drawer-open" : "custom-drawer-close",
        }}
      >
        <Toolbar />

        <List>
          <ListItem>
            <ListItemIcon className="toggle-button">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerOpen}
              >
                {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </ListItemIcon>
          </ListItem>
          {orderBy(get(props, "roleInfo.kushkiMenu", []), "priority", "asc")
            .filter(filterModulesBlocked)
            .map((kushkiMenu) =>
              kushkiMenu.componentId != ComponentIdEnum.ROLES_CONTROL ? (
                isEmpty(kushkiMenu.list) ? (
                  <div
                    className="sidebar-button"
                    onClick={() => handleClick(kushkiMenu.link)}
                  >
                    <ListItem button key={kushkiMenu.link}>
                      <ListItemIcon
                        className={
                          kushkiMenu.link === props.activeRoute
                            ? "custom-drawer-icon-selected"
                            : "custom-drawer-icon"
                        }
                      >
                        <SvgIcon
                          component={kushkiMenu.icon}
                          style={inlineStyles.listItemImage}
                          fontSize={"large"}
                        />
                      </ListItemIcon>
                      {open && (
                        <ListItemText
                          style={{
                            ...inlineStyles.listItemText,
                            color:
                              kushkiMenu.link === props.activeRoute
                                ? theme.palette.primary.main
                                : "#6D7781",
                          }}
                          primary={kushkiMenu.title}
                        />
                      )}
                    </ListItem>
                  </div>
                ) : (
                  <div className="sidebar-button">
                    <ListItem
                      button
                      onClick={() => handleListClick(kushkiMenu.link)}
                      key={kushkiMenu.link}
                    >
                      <ListItemIcon
                        className={
                          kushkiMenu.link === props.activeRoute
                            ? "custom-drawer-icon-selected"
                            : "custom-drawer-icon"
                        }
                      >
                        <SvgIcon
                          component={kushkiMenu.icon}
                          style={inlineStyles.listItemImage}
                          fontSize={"large"}
                        />
                      </ListItemIcon>

                      {open && (
                        <ListItemText
                          style={{
                            ...inlineStyles.listItemText,
                            color:
                              kushkiMenu.link === props.activeRoute
                                ? theme.palette.primary.main
                                : "#6D7781",
                          }}
                          primary={
                            <Grid container direction={"row"} spacing={2}>
                              {kushkiMenu.title.includes("Beta") ? (
                                <Grid item>
                                  {kushkiMenu.title.split(" ")[0] + " "}
                                  <span className={"beta-font"}>Beta</span>
                                </Grid>
                              ) : (
                                <Grid item>{kushkiMenu.title}</Grid>
                              )}

                              <Grid item>
                                {openList[kushkiMenu.link] &&
                                menuSelected === kushkiMenu.link ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </Grid>
                            </Grid>
                          }
                        />
                      )}
                    </ListItem>
                    <Collapse
                      in={
                        openList[kushkiMenu.link] &&
                        menuSelected === kushkiMenu.link
                      }
                    >
                      <List style={inlineStyles.listSubListMenu}>
                        {get(kushkiMenu, "list", [])
                          .filter(filterOnlyAllowedMerchants)
                          .map(
                            (
                              item: { title: string; link: string },
                              i: number
                            ) => (
                              <>
                                {hasIn(item, "subListMenu") ? (
                                  <>
                                    <ListItem key={item.title + i}>
                                      <ListItemText
                                        style={{
                                          ...inlineStyles.listItemText,
                                          paddingLeft: 55,
                                          color:
                                            item.link === props.activeRoute
                                              ? theme.palette.primary.main
                                              : "#6D7781",
                                        }}
                                        primary={item.title}
                                      />
                                      <ExpandLessIcon />
                                    </ListItem>
                                    <ListItem key={i + item.title}>
                                      <Collapse
                                        in={
                                          openList[kushkiMenu.link] &&
                                          menuSelected === kushkiMenu.link
                                        }
                                        timeout="auto"
                                      >
                                        {get(item, "subListMenu", []).map(
                                          (
                                            subMenu: {
                                              title: string;
                                              link: string;
                                            },
                                            index: number
                                          ) => (
                                            <div
                                              key={subMenu.title + index}
                                              onClick={() =>
                                                handleClick(subMenu.link)
                                              }
                                            >
                                              <ListItem
                                                button
                                                key={index + subMenu.link}
                                              >
                                                <ListItemText
                                                  style={{
                                                    ...inlineStyles.listItemText,
                                                    paddingLeft: 55,
                                                    color:
                                                      subMenu.link ===
                                                      props.activeRoute
                                                        ? theme.palette.primary
                                                            .main
                                                        : "#6D7781",
                                                  }}
                                                  primary={subMenu.title}
                                                />
                                              </ListItem>
                                            </div>
                                          )
                                        )}
                                      </Collapse>
                                    </ListItem>
                                  </>
                                ) : (
                                  <div onClick={() => handleClick(item.link)}>
                                    <ListItem button key={item.link + i}>
                                      <ListItemText
                                        style={{
                                          ...inlineStyles.listItemText,
                                          paddingLeft: 55,
                                          color:
                                            item.link === props.activeRoute
                                              ? theme.palette.primary.main
                                              : "#6D7781",
                                        }}
                                        primary={item.title}
                                      />
                                    </ListItem>
                                  </div>
                                )}
                              </>
                            )
                          )}
                      </List>
                    </Collapse>
                  </div>
                )
              ) : (
                ""
              )
            )}
          {viewCard && <CardActivateAccount />}
        </List>
        {dataRoleControl.length <= 0 ? (
          ""
        ) : (
          <>
            <Divider variant="middle" />
            <List style={inlineStyles.listControlRoles}>
              {dataRoleControl.map((menu, indx: number) => (
                <div
                  key={menu.link + indx}
                  className="sidebar-button"
                  onClick={() => handleClick(menu.link)}
                >
                  <ListItem button key={indx + menu.link}>
                    <ListItemIcon
                      className={
                        menu.link === props.activeRoute
                          ? "custom-drawer-icon-selected"
                          : "custom-drawer-icon"
                      }
                    >
                      <SvgIcon
                        component={menu.icon}
                        style={inlineStyles.listItemImage}
                        fontSize={"large"}
                      />
                    </ListItemIcon>

                    {open && (
                      <ListItemText
                        style={{
                          ...inlineStyles.listItemText,
                          color:
                            menu.link === props.activeRoute
                              ? theme.palette.primary.main
                              : "#6D7781",
                        }}
                        primary={menu.title}
                      />
                    )}
                  </ListItem>
                </div>
              ))}
            </List>
          </>
        )}
        {support && !props.roleInfo!.supportSessionActive ? (
          <CardSupport
            status={props.statusSession!.status}
            code={accessCode}
            openDialog={openLogoutConfirmModal}
            handleOpen={handleOpenModal}
            handleAccept={handleAcceptLogoutConfirmModal}
            handleClose={handleCloseLogoutConfirmModal}
          />
        ) : null}
      </Drawer>
    </div>
  );
};

SideBar.propTypes = {
  activeRoute: PropTypes.string,
};

const mapStateToProps: (state: IAppState) => IAppState = (
  state: IAppState
): IAppState => ({
  ...state,
});

const mapDispatchToProps: (dispatch: Dispatch) => IAppFunctionsProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): IAppFunctionsProps => ({
  setActiveRoute: (path: string): IAppAction => dispatch(setActiveRoute(path)),
  getStatusSession: (payload: string): void =>
    dispatch(statusCodeSupport(payload)),
  logoutSupportSessionRequest: (supportClose: unknown): void =>
    dispatch(logoutSupportSessionRequest(supportClose)),
  setHover: (link: string | null): void => dispatch(setHover(link)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
