import { routes } from "./Routes";
import { defaultTo, get } from "lodash";
import { LOCALSTORAGE } from "./LocalStorage_Enum";

const isOnlyRouteMerchantProperties = (): boolean => {
  return (
    window.location.pathname.includes(routes.MERCHANT_PROPERTIES) &&
    !window.location.pathname.includes(routes.MERCHANT_PROPERTIES_BATCH)
  );
};

export const CatalogLayout: string[] = [
  routes.NODE,
  routes.CREATE_NODE,
  routes.MERCHANT,
  routes.FINANCIAL_CONFIGURATION,
  routes.MERCHANT_RESUME,
  routes.CONSOLE_WALLET_DASHBOARD,
  routes.CONSOLE_WALLET,
  routes.CENTRALIZATION,
  routes.DEVELOPERS,
  routes.MERCHANT_WEBHOOK,
  routes.SERVICE_CONFIG,
  routes.CONSOLE_RATES,
  routes.MERCHANT_PREFERENCES,
  routes.PROCESSING,
  routes.BUSINESS_RULES,
  routes.PAYOUT_BUSINESS_RULES,
  routes.USERS,
  routes.CONSOLE_USER,
  isOnlyRouteMerchantProperties() ? "" : routes.MERCHANT_PROPERTIES,
];

export const OriginV3: string[] = [
  "createMerchantV3",
  "merchantResume",
  "consoleMerchant",
  "batchProcessing",
  "deferredMerchantV3",
];

export const CatalogLayoutCard: string[] = [
  routes.PROCESSOR_CARD,
  routes.PROCESSOR_TRANSFER,
  routes.PROCESSOR_PAYOUTS_TRANSFER,
  routes.PROCESSOR_CASH,
  routes.PROCESSOR_PAYOUTS_CASH,
  routes.PROCESSOR_TRANSFER_SUBSCRIPTION,
  routes.PROCESSOR_LIST,
  routes.PROCESSOR_PAYOUTS_CARD,
  routes.DEFERRED,
];

const matchPath = (paths: string[], route: string): boolean =>
  paths.includes("/" + route.split("/")[1]);

const CatalogConditionalLayout: string[] = [
  routes.CONSOLE_USER,
  routes.USERS,
  routes.CONSOLE_WALLET_DASHBOARD,
];

enum QueryParams {
  CONSOLE = "console",
}

export const validateStartWith = (route: string) => {
  const urlSearchParams: URLSearchParams = new URLSearchParams(
    window.location.search
  );

  const actualRoute = `/${route.split("/")[1]}`;

  const isRouteIncluded: boolean = CatalogLayout.includes(actualRoute);

  const isConditionalRouteIncluded: boolean =
    CatalogConditionalLayout.includes(actualRoute);

  const hasConsoleQueryParam: boolean =
    urlSearchParams.get(QueryParams.CONSOLE) === "3";

  return (
    (isRouteIncluded && !isConditionalRouteIncluded) ||
    (isConditionalRouteIncluded && hasConsoleQueryParam)
  );
};
export const validateCardPaths = (route: string): boolean =>
  matchPath(CatalogLayoutCard, route) &&
  OriginV3.includes(
    get(
      JSON.parse(
        defaultTo(
          localStorage.getItem(LOCALSTORAGE.MERCHANT_BASIC_INFORMATION),
          "{}"
        )
      ),
      "origin",
      ""
    )
  );

export const MerchantChargeBackRoles: string[] = [
  "BackofficeMasterCustomer",
  "BackofficeAdmin",
];

export const MerchantRoles: string[] = [
  "BackofficeMasterCustomer",
  "BackofficeUserMaster",
  "VirtualPOS",
  "BackofficeUserMaker",
  "BackofficeMasterCredentials",
  "WebcheckoutMaster",
  "BackofficeUserReader",
  "WebcheckoutBasic",
  "ExpressCheckoutMaster",
];
