import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Copy } from "react-feather";
import { clipboard } from "../../shared/clipboard";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";

interface ICardSupport {
  status: string;
  code: string;
  openDialog: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  handleAccept: () => void;
}

const styles = (theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    boxShadow: "none !important",
    borderRadius: "0px !important",
    backgroundColor: "#F7FAFC !important",
    width: "100%",
    color: "#023365",
    margin: "0px 15px",
  },
  typography: {
    color: "#023365",
    fontSize: "14px",
  },
  card: {
    boxShadow: "none !important",
    borderRadius: "4px !important",
    backgroundColor: "#FFFFFF",
    padding: 0,
    width: "100%",
    margin: "-10px 15px",
  },
  copyIcon: {
    padding: "8px !important",
    color: "#CBD5E0",
  },
  contentLabel: {
    fontStyle: "normal" as const,
    fontWeight: "normal" as const,
    fontSize: "13px",
    lineHeight: "136%",
    color: "#293036",
  },
});

const CardSupport: React.FC<ICardSupport> = (props: ICardSupport) => {
  const inlineStyles = styles(useTheme());
  const isActive: boolean = props.status === "ACTIVE";

  const handleCopy = (text: string | undefined) => () => {
    clipboard(text || "");
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        flexWrap="wrap"
        style={{ width: "100%", paddingBottom: 40 }}
      >
        <Card
          style={{
            ...inlineStyles.card,
            border: "1px solid rgba(0, 0, 0, 0.23)",
            borderColor: isActive ? "#0DC298" : "#023365",
          }}
        >
          <CardContent
            style={{
              width: "100%",
              backgroundColor: "#FFFFFF",
              padding: "8px 10px 8px 10px",
            }}
          >
            <Box
              flexGrow={2}
              style={{
                color: isActive ? "#0DC298" : "#023365",
                fontWeight: 500,
                textAlign: "center",
                fontSize: 14,
              }}
            >
              {isActive ? "Conectado" : "Sesión activa"}
            </Box>
            <Grid container>
              <Typography style={inlineStyles.contentLabel}>
                {isActive
                  ? "Un agente de soporte está conectado a su comercio."
                  : "En unos momentos un agente de soporte se conectará a su comercio."}
              </Typography>
            </Grid>
            <Box
              display="flex"
              flexWrap="wrap"
              flexGrow={1}
              alignItems="center"
              style={{ marginTop: "5px" }}
            >
              {!isActive && (
                <Box
                  style={{
                    color: "#293036",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography style={inlineStyles.contentLabel}>
                    {props.code}
                  </Typography>
                  <IconButton
                    style={inlineStyles.copyIcon}
                    onClick={handleCopy(props.code)}
                  >
                    <Copy size={14} />
                  </IconButton>
                </Box>
              )}
              <Box
                flexGrow={2}
                style={{
                  color: "#6D7781",
                  cursor: "pointer",
                  textAlign: "right",
                }}
                onClick={props.handleOpen}
              >
                <Typography
                  style={{ ...inlineStyles.contentLabel, color: "#6D7781" }}
                >
                  Cerrar sesion
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <ConfirmModal
        open={props.openDialog}
        titleText={"¿Deseas cerrar sesión?"}
        contentText={
          "Si cierras sesión ahora, el equipo de Soporte Kushki no tendrá acceso a visualizar tu cuenta en la Consola de Cliente."
        }
        acceptText="Cerrar sesión"
        handleClose={props.handleClose}
        handleAccept={props.handleAccept}
      />
    </React.Fragment>
  );
};

export default CardSupport;
