import React, { useEffect, useState } from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { navigateToUrl } from "single-spa";
import { Theme } from "@material-ui/core/styles";
import {
  Avatar,
  Box,
  Collapse,
  Divider,
  SvgIcon,
  Toolbar,
  Typography,
  useTheme,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import AppBar from "@material-ui/core/AppBar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import { X, LogOut, ChevronDown, User } from "react-feather";
import IconButton from "@material-ui/core/IconButton";
import BrasilFlag from "assets/flags/brazil.png";
import ChileFlag from "assets/flags/chile.png";
import ColombiaFlag from "assets/flags/colombia.png";
import EcuadorFlag from "assets/flags/ecuador.png";
import MexicoFlag from "assets/flags/mexico.png";
import PeruFlag from "assets/flags/peru.png";
import { IAppState, INotification } from "../store/reducer";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  IAppAction,
  logoutSupportSessionRequest,
  setActiveRoute,
  setHover,
  setNotification,
  statusCodeSupport,
} from "../store/actionCreators";
import { RoleService } from "../services/RoleService";
import { routes } from "../infrastructure/constants/Routes";
import ButtonBase from "@material-ui/core/ButtonBase";
import {
  concat,
  defaultTo,
  find,
  get,
  includes,
  isEmpty,
  isNil,
  orderBy,
  remove,
  set,
} from "lodash";
import { IKushkiMenu } from "../infrastructure/interfaces/IKushkiMenu";
import "./SideBar.css";
import PropTypes from "prop-types";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import CardSupport from "./CardSupport/CardSupport";
import { SessionStatusEnum } from "../shared/SessionStatusEnum";
import { ComponentIdEnum } from "../infrastructure/constants/ComponentIdEnum";

const drawerRoute = "/drawer";
const logoutRoute = "/logout";

const positionFixed = "fixed" as const;
const styles = (theme: Theme) => ({
  root: {
    position: positionFixed,
    top: "auto",
    bottom: 0,
    paddingTop: 10,
    paddingBottom: 10,
    background: "white",
    justifyItems: "center",
  },
  bottomNavigation: {
    width: "100%",
    color: "#293036",
  },
  drawerHeader: {
    background: theme.palette.background.default,
    padding: 10,
  },
  drawerBody: {
    padding: 5,
  },
  navItemsList: {
    width: 300,
  },
  avatarBox: {
    marginLeft: 10,
  },
  avatar: {
    background: theme.palette.primary.light,
    color: "white",
    fontSize: 14,
    marginRight: 10,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  avatarFlag: {
    marginLeft: 10,
    marginRight: 10,
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  merchantUserTitle: {
    flexGrow: 1,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 19,
    width: "170px",
  },
  userTitle: {
    flexGrow: 1,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  closeIcon: {
    marginRight: 5,
  },
  merchantSection: {
    padding: 10,
    paddingTop: 30,
    paddingLeft: 30,
  },
  merchantTitle: {
    marginLeft: 10,
    flexGrow: 1,
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  countryFlag: {
    width: 24,
    height: 24,
    marginLeft: 15,
    marginTop: 3,
  },
  listItem: {
    paddingRight: 16,
    paddingLeft: 16,
    paddingBottom: 16,
    paddingTop: 16,
  },
  listItemProfile: {
    paddingRight: 16,
    paddingLeft: 30,
    paddingBottom: 10,
    paddingTop: 5,
    justifyContent: "normal",
  },
  menu: {
    color: "#293036",
  },
  listItemIcon: {
    paddingLeft: 15,
    color: "#677784",
  },
  listItemText: {
    paddingLeft: 10,
    fontSize: 16,
    color: theme.palette.primary.main,
  },
  listItemImage: {
    marginTop: 5,
  },
  bottomItemImage: {
    width: 25,
    height: 25,
  },
  dropdown: {
    marginLeft: 5,
  },
  chevronDown: {
    marginLeft: 10,
    marginRight: 10,
  },
  divider: {
    marginTop: 7,
    marginBottom: 10,
  },
  collapseMenu: {
    paddingLeft: 45,
  },
  collapseMenuListButton: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
  },
});

interface IAppFunctionsProps {
  setActiveRoute: (path: string) => void;
  setNotification: (notify: INotification) => void;
  logoutSupportSessionRequest: (
    supportClose: unknown,
    closeBackoffice?: boolean
  ) => void;
  getStatusSession: (payload: string) => void;
  setHover: (link: string | null) => void;
}

const BottomNavBar = (props: IAppState & IAppFunctionsProps) => {
  const theme = useTheme();
  const inlineStyles = styles(theme);
  const statusSession: string[] = [
    SessionStatusEnum.COMPLETED,
    SessionStatusEnum.CLOSE,
  ];
  const dataRoleControl = orderBy(
    get(props, "roleInfo.kushkiMenu", []),
    "priority",
    "asc"
  ).filter((item) => item.componentId === ComponentIdEnum.ROLES_CONTROL);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const [support, setSupport] = React.useState(false);
  const [accessCode, setAccessCode] = React.useState<string>("");
  const [openLogoutConfirmModal, setOpenLogoutConfirmModal] =
    useState<boolean>(false);

  const handleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleBottomNavChange = (path: string) => {
    if (path === drawerRoute) {
      setDrawerOpen(true);
      return;
    }

    props.setActiveRoute(path);

    return navigateToUrl(path);
  };

  const handleNavItemClick = (path: string) => {
    props.setActiveRoute(path);
    if (props.roleInfo?.isAdmin) localStorage.removeItem("merchant");
    if (path === logoutRoute) {
      handleLogout();
      return;
    }

    return navigateToUrl(path);
  };

  const handleLogout = () => {
    if (localStorage.getItem("authPayload") !== null) {
      props.setNotification({
        message:
          "No es posible realizar ninguna acción en modo Asistencia Remota",
        show: true,
        type: "error",
      });
      return;
    } else if (localStorage.getItem("accessCodeSupport") !== null) {
      props.logoutSupportSessionRequest(
        {
          accessCode: localStorage.getItem("accessCodeSupport"),
          isCompleted: false,
          messageCloseSession: "Sessión cerrada por parte de Comercio",
        },
        true
      );
      return;
    }
    RoleService.logout();
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerOpen(open);
      if (!open) {
        setMenuOpen(false);
        props.setHover(null);
      }
    };

  const loadNavItems = () => (
    <div style={inlineStyles.navItemsList} role="presentation">
      <List>
        {orderBy(get(props, "roleInfo.kushkiMenu", []), "priority", "asc").map(
          (kushkiMenu: IKushkiMenu, index) =>
            kushkiMenu.componentId != ComponentIdEnum.ROLES_CONTROL ? (
              <div key={index}>
                <div
                  onClick={toggleDrawer(!!kushkiMenu.subKushkiMenu)}
                  onKeyDown={toggleDrawer(!!kushkiMenu.subKushkiMenu)}
                >
                  <ListItem
                    button
                    key={kushkiMenu.link}
                    style={inlineStyles.listItem}
                    selected={
                      includes(get(props, "activeRoute"), kushkiMenu.link) &&
                      !kushkiMenu.subKushkiMenu
                    }
                    onClick={() =>
                      !!kushkiMenu.subKushkiMenu
                        ? props.setHover(
                            !!kushkiMenu.hover ? null : kushkiMenu.link
                          )
                        : handleNavItemClick(kushkiMenu.link)
                    }
                  >
                    <ListItemIcon
                      style={{
                        minWidth: 1,
                        color:
                          includes(
                            get(props, "activeRoute"),
                            kushkiMenu.link
                          ) && !kushkiMenu.subKushkiMenu
                            ? theme.palette.primary.main
                            : "#6D7781",
                      }}
                    >
                      <SvgIcon
                        style={inlineStyles.listItemImage}
                        component={kushkiMenu.icon}
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={{
                        ...inlineStyles.listItemText,
                        color:
                          includes(props.activeRoute, kushkiMenu.link) &&
                          !kushkiMenu.subKushkiMenu
                            ? theme.palette.primary.main
                            : "#6D7781",
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        {kushkiMenu.title}
                        {!!kushkiMenu.subKushkiMenu && (
                          <Box ml={2} display="flex" alignItems="center">
                            {" "}
                            {!!kushkiMenu.hover ||
                            !!find(
                              kushkiMenu.subKushkiMenu,
                              (subKushkiMenu: IKushkiMenu) =>
                                props.activeRoute === subKushkiMenu.link
                            ) ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </Box>
                        )}
                      </Box>
                    </ListItemText>
                  </ListItem>
                </div>
                {loadSubKushkiMenu(kushkiMenu)}
              </div>
            ) : (
              ""
            )
        )}
      </List>
      {dataRoleControl.length <= 0 ? (
        ""
      ) : (
        <>
          <Divider variant="middle" style={inlineStyles.divider} />
          <List>
            {dataRoleControl.map((kushkiMenu: IKushkiMenu, index) => (
              <div key={index}>
                <div
                  onClick={toggleDrawer(!!kushkiMenu.subKushkiMenu)}
                  onKeyDown={toggleDrawer(!!kushkiMenu.subKushkiMenu)}
                >
                  <ListItem
                    button
                    key={kushkiMenu.link}
                    style={inlineStyles.listItem}
                    selected={
                      includes(get(props, "activeRoute"), kushkiMenu.link) &&
                      !kushkiMenu.subKushkiMenu
                    }
                    onClick={() =>
                      !!kushkiMenu.subKushkiMenu
                        ? props.setHover(
                            !!kushkiMenu.hover ? null : kushkiMenu.link
                          )
                        : handleNavItemClick(kushkiMenu.link)
                    }
                  >
                    <ListItemIcon
                      style={{
                        minWidth: 1,
                        color:
                          includes(
                            get(props, "activeRoute"),
                            kushkiMenu.link
                          ) && !kushkiMenu.subKushkiMenu
                            ? theme.palette.primary.main
                            : "#6D7781",
                      }}
                    >
                      <SvgIcon
                        style={inlineStyles.listItemImage}
                        component={kushkiMenu.icon}
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={{
                        ...inlineStyles.listItemText,
                        color:
                          includes(props.activeRoute, kushkiMenu.link) &&
                          !kushkiMenu.subKushkiMenu
                            ? theme.palette.primary.main
                            : "#6D7781",
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        {kushkiMenu.title}
                      </Box>
                    </ListItemText>
                  </ListItem>
                </div>
                {loadSubKushkiMenu(kushkiMenu)}
              </div>
            ))}
          </List>
        </>
      )}
    </div>
  );

  const loadCountryFlag = () => {
    const country: string = props.merchant?.country || "";

    const countryFlags: { [key: string]: string } = {
      Brasil: BrasilFlag,
      Chile: ChileFlag,
      Colombia: ColombiaFlag,
      Ecuador: EcuadorFlag,
      Mexico: MexicoFlag,
      Peru: PeruFlag,
    };

    return countryFlags[country];
  };

  const loadKushkiMenu = (): IKushkiMenu[] => {
    const menu: IKushkiMenu[] = Object.assign(
      [],
      orderBy(get(props, "roleInfo.kushkiMenu", []), "priority", "asc")
    );
    const aux: IKushkiMenu[] = [];

    if (!isNil(find(menu, ["title", "Inicio"]))) {
      set(aux, "0", find(menu, ["title", "Inicio"]));
      remove(menu, (n: IKushkiMenu) => {
        return n.title === "Inicio";
      });
    }

    if (!isNil(find(menu, ["title", "Transacciones"]))) {
      set(aux, "1", find(menu, ["title", "Transacciones"]));
      remove(menu, (n: IKushkiMenu) => {
        return n.title === "Transacciones";
      });
    }

    if (!isNil(find(menu, ["title", "Mi comercio"]))) {
      set(aux, "2", find(menu, ["title", "Mi Comercio"]));
      remove(menu, (n: IKushkiMenu) => {
        return n.title === "Mi Comercio";
      });
    }

    if (!isNil(find(menu, ["title", "Smartlinks"]))) {
      set(aux, "2", find(menu, ["title", "Smartlinks"]));
      remove(menu, (n: IKushkiMenu) => {
        return n.title === "Smartlinks";
      });
    }

    remove(aux, (n: IKushkiMenu) => {
      return isEmpty(n) || isNil(n);
    });

    return concat(aux, menu);
  };

  const loadSubKushkiMenu = (kushkiMenu: IKushkiMenu) => {
    return (
      !!kushkiMenu.subKushkiMenu && (
        <Collapse
          in={
            !!kushkiMenu.hover ||
            !!find(
              kushkiMenu.subKushkiMenu,
              (subKushkiMenu: IKushkiMenu) =>
                props.activeRoute === subKushkiMenu.link
            )
          }
          timeout="auto"
          unmountOnExit
          style={inlineStyles.collapseMenu}
        >
          <List component="div" disablePadding>
            {kushkiMenu.subKushkiMenu?.map(
              (subKushkiMenu: IKushkiMenu, index) => (
                <div
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                  key={index}
                >
                  <ListItem
                    button
                    key={index}
                    onClick={() => handleNavItemClick(subKushkiMenu.link)}
                    style={inlineStyles.collapseMenuListButton}
                  >
                    <ListItemText
                      primary={subKushkiMenu.title}
                      style={{
                        ...inlineStyles.listItemText,
                        color:
                          subKushkiMenu.link === props.activeRoute
                            ? theme.palette.primary.main
                            : "#6D7781",
                      }}
                    />
                  </ListItem>
                </div>
              )
            )}
          </List>
        </Collapse>
      )
    );
  };

  useEffect(() => {
    window.addEventListener(
      "build",
      function () {
        if (localStorage.accessCodeSupport) {
          const code: string = defaultTo(
            localStorage.getItem("accessCodeSupport"),
            ""
          );
          setAccessCode(code);
          props.getStatusSession(code);
        }
      },
      false
    );
  }, []);

  useEffect(() => {
    if (props.statusSession !== undefined) {
      setAccessCode(props.statusSession.accessCode);
      setSupport(!statusSession.includes(props.statusSession.status));
      if (statusSession.includes(props.statusSession.status)) {
        localStorage.removeItem("accessCodeSupport");
        window.location.reload();
      }
    }
  }, [props.statusSession]);

  const handleCloseLogoutConfirmModal = () => {
    setOpenLogoutConfirmModal(false);
  };
  const handleAcceptLogoutConfirmModal = () => {
    setOpenLogoutConfirmModal(false);
    props.logoutSupportSessionRequest({
      accessCode,
      isCompleted: false,
      messageCloseSession: "Sessión cerrada por parte de Comercio",
    });
  };
  const handleOpenModal = () => {
    setOpenLogoutConfirmModal(true);
  };

  return (
    <>
      <AppBar style={inlineStyles.root}>
        <Toolbar disableGutters>
          <BottomNavigation
            style={inlineStyles.bottomNavigation}
            value={props.activeRoute}
            onChange={(_event, path) => {
              handleBottomNavChange(path);
            }}
            showLabels
          >
            {loadKushkiMenu()
              .slice(0, 3)
              .map((kushkiMenu: IKushkiMenu, index: number) => (
                <BottomNavigationAction
                  key={index}
                  value={kushkiMenu.link}
                  label={
                    <Typography
                      variant="body2"
                      noWrap={true}
                      style={
                        kushkiMenu.link === props.activeRoute
                          ? { fontSize: 12, fontWeight: 500, width: 80 }
                          : { fontSize: 11, width: 80 }
                      }
                    >
                      {kushkiMenu.title}
                    </Typography>
                  }
                  icon={
                    <SvgIcon
                      viewBox="0 0 17 17"
                      component={kushkiMenu.icon}
                      style={
                        kushkiMenu.link === props.activeRoute
                          ? { padding: 1, width: 35, height: "auto" }
                          : { padding: 4, width: 30, height: "auto" }
                      }
                    />
                  }
                />
              ))}
            <BottomNavigationAction
              style={inlineStyles.menu}
              value={drawerRoute}
              label={
                <Typography style={{ fontSize: 11, color: "#023365" }}>
                  Menú
                </Typography>
              }
              icon={
                <MenuIcon
                  style={{
                    color: "#023365",
                    padding: 4,
                    width: 30,
                    height: "auto",
                  }}
                />
              }
            />
          </BottomNavigation>
        </Toolbar>
      </AppBar>
      <div>
        <React.Fragment key="right">
          <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            classes={{ paper: "bottom-nav-bar-drawer-paper" }}
          >
            <Box style={inlineStyles.drawerHeader}>
              <Toolbar disableGutters>
                {!props.roleInfo?.isAdmin ? (
                  <Avatar
                    src={loadCountryFlag()}
                    style={inlineStyles.avatarFlag}
                  >
                    {props.roleInfo?.userInitials}
                  </Avatar>
                ) : (
                  ""
                )}
                <Typography
                  noWrap={true}
                  style={
                    props.roleInfo?.isAdmin
                      ? inlineStyles.merchantTitle
                      : inlineStyles.merchantUserTitle
                  }
                >
                  {props.roleInfo?.isAdmin ? "Admin" : props.merchant?.name}
                </Typography>
                <IconButton
                  style={inlineStyles.closeIcon}
                  onClick={toggleDrawer(false)}
                >
                  <X color={theme.palette.primary.main} size={30} />
                </IconButton>
              </Toolbar>
            </Box>
            <Box mt={1}>
              <ButtonBase style={inlineStyles.dropdown} onClick={handleMenu}>
                <Toolbar disableGutters>
                  <Box style={inlineStyles.avatarBox}>
                    {props.roleInfo?.userInitials !== "" && (
                      <Avatar style={inlineStyles.avatar}>
                        {props.roleInfo?.userInitials}
                      </Avatar>
                    )}
                  </Box>
                  <Box style={inlineStyles.userTitle}>
                    {props.roleInfo?.fullName}
                  </Box>
                  <ChevronDown
                    color={theme.palette.primary.main}
                    style={inlineStyles.chevronDown}
                  />
                </Toolbar>
              </ButtonBase>
              {menuOpen ? (
                <React.Fragment>
                  <div
                    style={inlineStyles.navItemsList}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                  >
                    <List>
                      <ListItem
                        style={inlineStyles.listItemProfile}
                        button
                        key={routes.PROFILE}
                        onClick={() => handleNavItemClick(routes.PROFILE)}
                      >
                        <ListItemIcon style={{ minWidth: 20 }}>
                          <User style={{ width: "18px", height: "18px" }} />
                        </ListItemIcon>
                        <Typography
                          style={{ fontSize: 15, paddingLeft: 10 }}
                          variant="inherit"
                          color="textPrimary"
                        >
                          Mi Comercio
                        </Typography>
                      </ListItem>
                      <ListItem
                        style={inlineStyles.listItemProfile}
                        button
                        key={logoutRoute}
                        onClick={() => handleNavItemClick(logoutRoute)}
                      >
                        <ListItemIcon style={{ minWidth: 20 }}>
                          <LogOut style={{ width: "18px", height: "18px" }} />
                        </ListItemIcon>
                        <Typography
                          style={{ fontSize: 15, paddingLeft: 10 }}
                          variant="inherit"
                          color="textPrimary"
                        >
                          Cerrar Sesión
                        </Typography>
                      </ListItem>
                    </List>
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}
            </Box>
            <Divider variant="middle" style={inlineStyles.divider} />
            {loadNavItems()}
            {support ? (
              <CardSupport
                status={props.statusSession!.status}
                code={accessCode}
                openDialog={openLogoutConfirmModal}
                handleOpen={handleOpenModal}
                handleAccept={handleAcceptLogoutConfirmModal}
                handleClose={handleCloseLogoutConfirmModal}
              />
            ) : null}
          </Drawer>
        </React.Fragment>
      </div>
    </>
  );
};

BottomNavBar.propTypes = {
  activeRoute: PropTypes.string,
  setHover: PropTypes.any,
};

const mapStateToProps: (state: IAppState) => IAppState = (
  state: IAppState
): IAppState => ({
  ...state,
});

const mapDispatchToProps: (dispatch: Dispatch) => IAppFunctionsProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): IAppFunctionsProps => ({
  setActiveRoute: (path: string): IAppAction => dispatch(setActiveRoute(path)),
  setNotification: (notify: INotification): IAppAction =>
    dispatch(setNotification(notify)),
  logoutSupportSessionRequest: (
    supportClose: unknown,
    closeBackoffice?: boolean
  ): void =>
    dispatch(logoutSupportSessionRequest(supportClose, closeBackoffice)),
  getStatusSession: (payload: string): void =>
    dispatch(statusCodeSupport(payload)),
  setHover: (link: string | null): void => dispatch(setHover(link)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BottomNavBar);
