import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { StylesProvider, Theme } from "@material-ui/core/styles";
import TopBar from "../components/TopBar";
import SideBar from "../components/SideBar";
import MainContent from "../components/MainContent";
import BottomNavBar from "../components/BottomNavBar";
import TopBarMobile from "../components/TopBarMobile";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { RoleService } from "../services/RoleService";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  IAppAction,
  initializeAppInfo,
  logoutSupportSessionRequest,
  setActiveRoute,
  setMerchantInfo,
  setNotification,
} from "../store/actionCreators";
import { IAppState, INotification } from "../store/reducer";
import { ThunkDispatch } from "redux-thunk";
import { routes } from "../infrastructure/constants/Routes";
import { IMerchantForm } from "../infrastructure/interfaces/IMerchantForm";
import { defaultTo, find, get, isNil } from "lodash";
import { environment } from "../environments/environment";
import NotificationSnackbar from "../components/NotificationSnackbar";
import {
  hasMerchantRoles,
  MENU_BACKOFFICE_COMPLIANCE,
  MENU_BACKOFFICE_MASTER_CREDENTIALS,
  MENU_BACKOFFICE_MASTER_CUSTOMER,
  MENU_CHARGEBACK_MANAGER,
} from "../infrastructure/constants/KushkiMenu";
import { LOCALSTORAGE } from "../infrastructure/constants/LocalStorage_Enum";
import { useLocation } from "react-router-dom";
import {
  MerchantResume,
  ResumeModal,
} from "../components/ResumeModal/ResumeModal";
import {
  InvalidTabModalPartialPath,
  InvalidTabModalPaths,
} from "../infrastructure/constants/InvalidTabModalPaths";
import { generateClassName } from "../infrastructure/constants/ClassNameGenerator";
import {
  MerchantRoles,
  validateCardPaths,
  validateStartWith,
} from "../infrastructure/constants/CatalogLayout";

interface IAppFunctionsProps {
  initializeAppInfo: () => void;
  setMerchantInfo: (payload: IMerchantForm | null) => void;
  setActiveRoute: (path: string) => void;
  setNotification: (notify: INotification) => void;
  logoutSupportSessionRequest: (supportClose: unknown) => void;
}

const styles = (theme: Theme) => ({
  root: {
    display: "flex",
    background: theme.palette.background.default,
  },
  snackbar: {
    "& .MuiPaper-root": {
      color: "#FFFFFF",
      backgroundColor: "#E24763",
    },
  },
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const redirectBlockedPathsWithMerchant = (pathname: string) => {
  const merchantsBlocked = find(environment.merchantsBlockedNavigation, {
    merchantId: localStorage.getItem(LOCALSTORAGE.MERCHANT_ID) ?? "",
  });

  const pathnameStart = defaultTo(pathname.match(/^\/[^\/]+/), [""])[0];

  if (merchantsBlocked?.routes?.includes(pathnameStart)) {
    window.history.pushState(undefined, "", "/dashboard");
  }
};

const Nav: React.FC<IAppFunctionsProps> = (
  props: IAppState & IAppFunctionsProps
) => {
  const query = useQuery();
  const location = useLocation();
  const theme = useTheme();
  const inlineStyles = styles(theme);
  const [showSpa, setShowSpa] = useState<boolean>(false);
  const [disableSideBar, setDisableSideBar] = useState<boolean>(false);
  const [disableTopBar, setDisableTopBar] = useState<boolean>(false);
  const [showTabModal, setShowTableModal] = useState<boolean>(false);
  const [merchantResume, setMerchantResume] = useState<MerchantResume>(
    {} as MerchantResume
  );
  const [showMenuItemConsole, setShowMenuItemConsole] =
    useState<boolean>(false);

  const isMobile: boolean = useMediaQuery("(max-width:767px)");
  const isProduction: boolean = environment.envName === "primary";
  const [webcheckout, setWebcheckout] = useState<boolean>(false);
  const [isLayout, setIsLayout] = useState<boolean>(false);

  useEffect(() => {
    const isAuthRoute = window.location.pathname.startsWith(routes.AUTH);
    const isLoginInternalRoute = window.location.pathname.startsWith(
      routes.LOGIN_INTERNAL
    );
    if (isAuthRoute || isLoginInternalRoute) return;

    if (!RoleService.hasPayload() || RoleService.isJWTExpired()) {
      RoleService.logout();
      return;
    }

    setInterval(() => RoleService.logout(), RoleService.getJWTLeftTime());

    props.initializeAppInfo();
    setShowSpa(true);
  }, []);

  useEffect(() => {
    let hideSideBar: string | null = query.get("hideSideBar");
    const hideTopBar: string | null = query.get("hideTopBar");
    const route = window.location.pathname;
    if (validateStartWith(route) || validateCardPaths(route)) {
      setIsLayout(true);
    }

    const menuItem = query.get("menuItem");

    if (menuItem === null && showMenuItemConsole) {
      setShowMenuItemConsole(false);
    } else if (menuItem === "true" && !showMenuItemConsole) {
      setShowMenuItemConsole(true);
    }

    const createdMerchant = JSON.parse(
      localStorage.getItem(LOCALSTORAGE.MERCHANT_RESUME) as string
    ) as MerchantResume;
    setMerchantResume(createdMerchant);

    if (createdMerchant && !hideSideBar) {
      if (
        location.pathname.includes(
          get(createdMerchant.merchantInfo, "publicMerchantId")
        )
      ) {
        hideSideBar = "true";
      }
    }

    if (window.location.pathname.startsWith("/subscriptions-legacy")) {
      window.location.replace("/subscriptions");
    }

    if (
      RoleService.isComplianceRoleUnique() &&
      window.location.pathname.startsWith("/dashboard")
    )
      window.location.href = MENU_BACKOFFICE_COMPLIANCE[0].link;

    if (
      RoleService.isChargebackManagerUser() &&
      window.location.pathname.startsWith("/dashboard")
    )
      window.location.href = escape(MENU_CHARGEBACK_MANAGER[0].list![0].link);

    if (
      window.location.pathname.startsWith("/dashboard") &&
      hasMerchantRoles(MerchantRoles) &&
      !window.location.pathname.startsWith("/dashboard-metrics")
    )
      window.location.href = MENU_BACKOFFICE_MASTER_CUSTOMER[0].link;

    if (
      RoleService.isMasterCredentials() &&
      !RoleService.isMaster() &&
      window.location.pathname.startsWith("/dashboard")
    )
      window.location.href = MENU_BACKOFFICE_MASTER_CREDENTIALS[0].link;

    const isCreateMerchant =
      window.location.pathname.startsWith("/create-merchant");
    setDisableSideBar(
      (!!hideSideBar && hideSideBar === "true") || isCreateMerchant
    );
    setDisableTopBar(!!hideTopBar && hideTopBar === "true");
    const invalidPath =
      InvalidTabModalPaths.find((path) => window.location.pathname === path) ||
      InvalidTabModalPartialPath.find((path) =>
        window.location.pathname.includes(path)
      );
    setShowTableModal(hideSideBar === "true" && !invalidPath);
    validateMerchantInfo();
    redirectBlockedPathsWithMerchant(location.pathname);
  }, [location]);

  useEffect(() => {
    const handleStorageChange = () => {
      validateMerchantInfo();
    };
    window.addEventListener("local-storage", handleStorageChange);
    return () => {
      window.removeEventListener("local-storage", handleStorageChange);
    };
  }, []);
  const validateMerchantInfo = () => {
    const merchantId: string | null = query.get("merchantId");
    const publicMerchantId: string | null = query.get("publicMerchantId");
    const hasMerchantId: boolean = !!merchantId || !!publicMerchantId;
    const pathWithMerchant: boolean = validatePathWithMerchant();
    if (
      window.location.pathname.startsWith("/webcheckout") ||
      window.location.pathname.startsWith("/kform/")
    )
      setWebcheckout(true);
    else setWebcheckout(false);
    if (localStorage.getItem("isAdmin") !== "true") return;
    if (
      pathWithMerchant ||
      (window.location.pathname.startsWith("/users") && hasMerchantId)
    ) {
      props.setMerchantInfo(
        !isNil(localStorage.getItem(LOCALSTORAGE.MERCHANT_BASIC_INFORMATION))
          ? JSON.parse(
              localStorage.getItem(
                LOCALSTORAGE.MERCHANT_BASIC_INFORMATION
              ) as string
            )
          : null
      );
      return;
    }

    window.localStorage.removeItem(LOCALSTORAGE.MERCHANT_BASIC_INFORMATION);
    props.setMerchantInfo(null);
  };

  const validatePathWithMerchant = () => {
    return (
      window.location.pathname.startsWith("/credentials") ||
      window.location.pathname.startsWith("/config-merchant") ||
      window.location.pathname.startsWith("/webhook") ||
      window.location.pathname.startsWith("/merchant-webhook") ||
      window.location.pathname.startsWith("/merchant-properties") ||
      window.location.pathname.startsWith("/merchant-rates") ||
      window.location.pathname.startsWith("/billing-merchant/charge") ||
      window.location.pathname.startsWith("/billing-merchant/invoice") ||
      window.location.pathname.startsWith("/merchants/profile/") ||
      window.location.pathname.startsWith("/config-merchant/merchant/") ||
      window.location.pathname.startsWith("/processor-card") ||
      window.location.pathname.startsWith("/processor-list") ||
      window.location.pathname.startsWith("/processor-payouts-cash") ||
      window.location.pathname.startsWith("/processor-payouts-transfer") ||
      window.location.pathname.startsWith(
        "/processor-transfer-subscriptions"
      ) ||
      window.location.pathname.startsWith("/processor-cash") ||
      window.location.pathname.startsWith("/processor-transfer") ||
      window.location.pathname.startsWith("/rules") ||
      window.location.pathname.startsWith("/deferred") ||
      window.location.pathname.startsWith("/create-merchant/details") ||
      window.location.pathname.startsWith("/merchants") ||
      window.location.pathname.startsWith("/credentials") ||
      window.location.pathname.startsWith("/rate") ||
      window.location.pathname.startsWith("/deferred") ||
      window.location.pathname.startsWith("/payouts-transaction") ||
      window.location.pathname.startsWith("/merchant-people") ||
      window.location.pathname.startsWith("/wallet-dashboard") ||
      window.location.pathname.startsWith("/processor-payouts-card")
    );
  };

  const close = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    props.setNotification({ show: false, message: "", type: "success" });
  };
  const sideBar = () => {
    return (
      <>
        <SideBar />
      </>
    );
  };

  const renderBackoffice = () => (
    <>
      {isMobile ? (
        <>
          <TopBarMobile
            isProduction={isProduction}
            merchantInfo={get(props, "merchant")}
            setActiveRoute={get(props, "setActiveRoute")}
            roleInfo={get(props, "roleInfo")}
          />
          <BottomNavBar />
        </>
      ) : (
        <>
          {!disableTopBar && (
            <TopBar
              isProduction={isProduction}
              merchantInfo={get(props, "merchant")}
              roleInfo={get(props, "roleInfo")}
              setActiveRoute={get(props, "setActiveRoute")}
              setNotification={get(props, "setNotification")}
              logoutSupportSessionRequest={get(
                props,
                "logoutSupportSessionRequest"
              )}
            />
          )}
          {!webcheckout && disableSideBar
            ? null
            : !disableSideBar && webcheckout
            ? null
            : !webcheckout && !disableSideBar
            ? sideBar()
            : null}
        </>
      )}
      {showTabModal && <ResumeModal merchantResume={merchantResume} />}
      <NotificationSnackbar
        msg={get(props, "notification.message")}
        open={get(props, "notification.show")}
        type={get(props, "notification.type")}
        handlerClose={close}
      />
      <MainContent />
    </>
  );

  const renderConsole = () => (
    <Box width={1}>
      <Box height={validateCardPaths(window.location.pathname) ? 0 : 93}>
        <div id="single-spa-application:spa-console-layout" />
      </Box>
      <Box paddingLeft={showMenuItemConsole ? "275px" : undefined}>
        <MainContent />
      </Box>
    </Box>
  );

  const renderSpa = () => {
    return isLayout ? renderConsole() : renderBackoffice();
  };

  return (
    <StylesProvider injectFirst generateClassName={generateClassName}>
      <div style={inlineStyles.root}>
        <CssBaseline />
        {showSpa ? (
          renderSpa()
        ) : (
          <div id="single-spa-application:spa-backoffice-login"></div>
        )}
      </div>
    </StylesProvider>
  );
};

const mapDispatchToProps: (dispatch: Dispatch) => IAppFunctionsProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): IAppFunctionsProps => ({
  initializeAppInfo: (): void => dispatch(initializeAppInfo()),
  setMerchantInfo: (payload: IMerchantForm | null): void =>
    dispatch(setMerchantInfo(payload)),
  setActiveRoute: (path: string): IAppAction => dispatch(setActiveRoute(path)),
  setNotification: (notify: INotification): IAppAction =>
    dispatch(setNotification(notify)),
  logoutSupportSessionRequest: (
    supportClose: unknown,
    closeBackoffice?: boolean
  ): void =>
    dispatch(logoutSupportSessionRequest(supportClose, closeBackoffice)),
});

const mapStateToProps: (state: IAppState) => IAppState = (
  state: IAppState
): IAppState => ({
  ...state,
});

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
