import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#023365",
      dark: "#0E1E2E",
      light: "#1E65AE",
    },
    secondary: {
      main: "#00e6b2",
      dark: "#0dc298",
      light: "#defff7",
    },
    info: {
      main: "#4498EE",
      dark: "#023365",
      light: "#D4EAFF",
    },
    error: {
      main: "#E24763",
      dark: "#AD0C2A",
      light: "#FFDFE5",
    },
    warning: {
      main: "#FFD740",
      dark: "#FFAB00",
      light: "#FFF6D1",
    },
    success: {
      main: "#00E6B2",
      dark: "#0DC298",
      light: "#DEFFF7",
    },
    background: {
      paper: "#fff",
      default: "#F0F4F9",
    },
    text: {
      primary: "#293036",
      secondary: "rgba(46, 61, 74, 0.70)",
      disabled: "rgba(46, 61, 74, 0.36)",
    },
  },
  typography: {
    fontFamily: "IBM Plex Sans,sans-serif",
    h1: {
      fontSize: "40px",
      fontWeight: 200,
      lineHeight: "140%",
      fontStyle: "normal",
    },
    h2: {
      fontSize: "32px",
      fontWeight: 500,
      lineHeight: "140%",
      fontStyle: "normal",
    },
    h3: {
      fontSize: "28px",
      fontWeight: 500,
      lineHeight: "140%",
      fontStyle: "normal",
    },
    h4: {
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: "140%",
      fontStyle: "normal",
    },
    h5: {
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "140%",
      fontStyle: "normal",
    },
    h6: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "140%",
      fontStyle: "normal",
    },
    subtitle2: {
      fontSize: "12px",
      fontWeight: 350,
    },
  },
});

export default theme;
