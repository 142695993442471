import axios, { AxiosError } from "axios";

// Constants
const STORAGE_KEY = "jwt";
const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
};

const instance = axios.create({
  headers: {
    ...DEFAULT_HEADERS,
    Authorization: localStorage.getItem("jwt") || "",
  },
});

// Add request interceptor to update token
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(STORAGE_KEY);
    if (token && config.headers) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error: AxiosError) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => Promise.reject(error)
);

export default instance;
