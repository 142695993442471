import { IKushkiMenu } from "../interfaces/IKushkiMenu";
import { ReactComponent as HomeIcon } from "../../assets/imagen/home.svg";
import { ReactComponent as TransactionIcon } from "../../assets/imagen/transaction.svg";
import { ReactComponent as MerchantIcon } from "../../assets/imagen/merchant.svg";
import { ReactComponent as RuleIcon } from "../../assets/imagen/rules.svg";
import { ReactComponent as FraudPreventionIcon } from "../../assets/imagen/shield.svg";
import { ReactComponent as SamrtlinkIcon } from "../../assets/imagen/smartlink.svg";
import { ReactComponent as SuscriptionIcon } from "../../assets/imagen/suscription.svg";
import { ReactComponent as UserIcon } from "../../assets/imagen/user.svg";
import { ReactComponent as ResellerIcon } from "../../assets/imagen/resellers.svg";
import { ReactComponent as BillingIcon } from "../../assets/imagen/billing.svg";
import { ReactComponent as FormIcon } from "../../assets/imagen/form.svg";
import { ReactComponent as SettingIcon } from "../../assets/imagen/setting.svg";
import { ReactComponent as BoltIcon } from "../../assets/imagen/bolt.svg";
import { ReactComponent as DeveloperIcon } from "../../assets/imagen/developer.svg";
import { ReactComponent as ComplianceIcon } from "../../assets/imagen/compliance.svg";
import { ReactComponent as WalletIcon } from "../../assets/imagen/wallet.svg";

import { routes } from "./Routes";
import { defaultTo, get } from "lodash";
import { LOCALSTORAGE } from "./LocalStorage_Enum";
import { ComponentIdEnum } from "./ComponentIdEnum";
import { RequiredRolEnum } from "./RequiredRolEnum";
import { IPayload } from "../interfaces/IPayload";
import { environment } from "../../environments/environment";

export const hasMerchantRoles = (rolesCatalog: string[]): boolean => {
  const merchant_roles = [...rolesCatalog];

  const payload: IPayload = JSON.parse(
    defaultTo(localStorage.getItem(LOCALSTORAGE.PAYLOAD), "{}")
  );
  const roles: string[] = get(payload, "[cognito:groups]", []);

  for (let step = 0; step <= roles.length; step++) {
    const foundRole: boolean = merchant_roles.includes(roles[step]);

    if (foundRole) return foundRole;
  }

  return false;
};

const HOME: IKushkiMenu = {
  title: "Inicio",
  icon: HomeIcon,
  link: routes.DASHBOARD,
  home: true,
  priority: 1,
  componentId: ComponentIdEnum.HOME,
  requiredRol: RequiredRolEnum.HOME,
};

const FRAUD_PREVENTION: IKushkiMenu = {
  title: "Prevención de Fraude",
  icon: FraudPreventionIcon,
  link: routes.FRAUD_PREVENTION,
  home: true,
  priority: 3,
  componentId: ComponentIdEnum.FRAUD_PREVENTION,
  requiredRol: RequiredRolEnum.FRAUD_PREVENTION,
};

const MERCHANTS: IKushkiMenu = {
  title: "Comercios",
  icon: MerchantIcon,
  link: routes.MERCHANTS,
  home: true,
  priority: 2,
  componentId: ComponentIdEnum.MERCHANTS,
  requiredRol: RequiredRolEnum.MERCHANTS,
};

const COMPLIANCE_MERCHANTS: IKushkiMenu = {
  title: "Comercios",
  icon: MerchantIcon,
  link: routes.MERCHANTS,
  home: true,
  priority: 1,
  componentId: ComponentIdEnum.COMPLIANCE_MERCHANTS,
  requiredRol: RequiredRolEnum.COMPLIANCE_MERCHANTS,
};

const TRANSACTIONS_CHARGES: IKushkiMenu = {
  title: "Transacciones",
  icon: TransactionIcon,
  link: routes.CONSOLE_TRANSACTIONS,
  home: true,
  priority: 2,
  list: [
    {
      priority: 1,
      title: "Cobros",
      link: routes.CONSOLE_TRANSACTIONS,
      componentId: ComponentIdEnum.TRANSACTIONS_TRANSACTIONS,
      requiredRol: RequiredRolEnum.TRANSACTIONS_TRANSACTIONS,
    },
  ],
  componentId: ComponentIdEnum.TRANSACTIONS,
  requiredRol: RequiredRolEnum.TRANSACTIONS,
};

const TRANSACTIONS: IKushkiMenu = {
  title: "Transacciones",
  icon: TransactionIcon,
  link: routes.CONSOLE_TRANSACTIONS,
  home: true,
  priority: 2,
  list: [
    {
      priority: 1,
      title: "Cobros",
      link: routes.CONSOLE_TRANSACTIONS,
      componentId: ComponentIdEnum.TRANSACTIONS_TRANSACTIONS,
      requiredRol: RequiredRolEnum.TRANSACTIONS_TRANSACTIONS,
    },
    {
      priority: 2,
      title: "Pagos",
      link: routes.CONSOLE_PAYOUTS_TRANSACTION,
      componentId: ComponentIdEnum.TRANSACTIONS_PAYOUTS_TRANSACTION,
      requiredRol: RequiredRolEnum.TRANSACTIONS_PAYOUTS_TRANSACTION,
    },
    {
      priority: 3,
      title: "Contracargos",
      link: routes.CONSOLE_CHARGEBACK,
      componentId: ComponentIdEnum.TRANSACTIONS_CHARGEBACK,
      requiredRol: RequiredRolEnum.TRANSACTIONS_CHARGEBACK,
    },
    {
      allowedMerchants: [
        "20000000105547465000",
        "20000000105277640000",
        "20000000102948160000",
      ],
      priority: 4,
      title: "Dispersiones",
      link: routes.PAYOUTS_DISPERSION,
      componentId: ComponentIdEnum.TRANSACTIONS_PAYOUTS_TRANSACTION,
      requiredRol: RequiredRolEnum.TRANSACTIONS_PAYOUTS_TRANSACTION,
    },
  ],
  componentId: ComponentIdEnum.TRANSACTIONS,
  requiredRol: RequiredRolEnum.TRANSACTIONS,
};

const MY_MERCHANTS: IKushkiMenu = {
  title: "Mi Comercio",
  icon: MerchantIcon,
  link: routes.MY_MERCHANT,
  home: true,
  priority: 4,
  componentId: ComponentIdEnum.MY_MERCHANTS,
  requiredRol: RequiredRolEnum.MY_MERCHANTS,
};

const MONITORING: IKushkiMenu = {
  title: "Prevención de Fraude",
  icon: RuleIcon,
  link: routes.MONITORING,
  home: true,
  priority: 4,
  componentId: ComponentIdEnum.MONITORING,
  requiredRol: RequiredRolEnum.MONITORING,
};

const USERS: IKushkiMenu = {
  title: "Usuarios",
  icon: UserIcon,
  link: routes.CONSOLE_USER_USERS,
  home: true,
  priority: 6,
  componentId: ComponentIdEnum.USERS,
  requiredRol: RequiredRolEnum.USERS,
};

const ALLIES: IKushkiMenu = {
  title: "Aliados",
  icon: ResellerIcon,
  link: routes.RESELLERS,
  home: true,
  priority: 7,
  componentId: ComponentIdEnum.ALLIES,
  requiredRol: RequiredRolEnum.ALLIES,
};
const SUBSCRIPTIONS: IKushkiMenu = {
  title: "Suscripciones",
  icon: SuscriptionIcon,
  link: routes.SUBSCRIPTIONS_V2,
  home: true,
  priority: 8,
  componentId: ComponentIdEnum.SUBSCRIPTIONS,
  requiredRol: RequiredRolEnum.SUBSCRIPTIONS,
};

const SMARTLINKS: IKushkiMenu = {
  title: "Smartlinks",
  icon: SamrtlinkIcon,
  link: routes.SMARTLINKS,
  home: true,
  priority: 9,
  componentId: ComponentIdEnum.SMARTLINKS,
  requiredRol: RequiredRolEnum.SMARTLINKS,
};

const WALLET: IKushkiMenu = {
  title: "Billetera",
  icon: WalletIcon,
  link: routes.WALLET_DASHBOARD,
  home: true,
  priority: 9,
};

const BILLING_DASHBOARD_EXECUTOR: IKushkiMenu = {
  title: "Facturación",
  icon: BillingIcon,
  link: routes.BILLING_DASHBOARD,
  home: true,
  list: [
    {
      priority: 1,
      title: "Ejecutor",
      link: routes.EXECUTOR,
      componentId: ComponentIdEnum.BILLING_DASHBOARD_EXECUTOR_EXECUTOR,
      requiredRol: RequiredRolEnum.BILLING_DASHBOARD_EXECUTOR_EXECUTOR,
    },
  ],
  priority: 10,
  componentId: ComponentIdEnum.BILLING,
  requiredRol: RequiredRolEnum.BILLING_DASHBOARD_EXECUTOR,
};

const BILLING_DASHBOARD_VALIDATOR: IKushkiMenu = {
  title: "Facturación",
  icon: BillingIcon,
  link: routes.BILLING_DASHBOARD,
  home: true,
  list: [
    {
      priority: 2,
      title: "Aprobador",
      link: routes.VALIDATOR,
      componentId: ComponentIdEnum.BILLING_DASHBOARD_VALIDATOR_VALIDATOR,
      requiredRol: RequiredRolEnum.BILLING_DASHBOARD_VALIDATOR_VALIDATOR,
    },
  ],
  priority: 11,
  componentId: ComponentIdEnum.BILLING,
  requiredRol: RequiredRolEnum.BILLING_DASHBOARD_VALIDATOR,
};

const BILLING_DASHBOARD_MANUAL_CONTINGENCY: IKushkiMenu = {
  title: "Facturación",
  icon: BillingIcon,
  link: routes.BILLING_DASHBOARD,
  home: true,
  list: [
    {
      priority: 3,
      title: "Contingencia Manual",
      link: routes.MANUAL_CONTINGENCY,
    },
  ],
  priority: 12,
};

const SNR: IKushkiMenu = {
  title: "SNR",
  icon: FormIcon,
  link: routes.SNR,
  home: true,
  priority: 18,
  componentId: ComponentIdEnum.SNR,
  requiredRol: RequiredRolEnum.SNR,
};
const VEHISCORE: IKushkiMenu = {
  title: "VehiScore",
  icon: FormIcon,
  link: routes.VEHISCORE,
  home: true,
  priority: 18,
  componentId: ComponentIdEnum.VEHISCORE,
  requiredRol: RequiredRolEnum.VEHISCORE,
};
const REFUNDS_CONTROL: IKushkiMenu = {
  title: "Ctrl de Reembolsos",
  icon: BillingIcon,
  link: routes.REFUNDS_CONTROL,
  home: true,
  priority: 18,
  componentId: ComponentIdEnum.REFUNDS_CONTROL,
  requiredRol: RequiredRolEnum.REFUNDS_CONTROL,
};

const MGUAYAQUIL: IKushkiMenu = {
  title: "Municipio de Guayaquil",
  icon: FormIcon,
  link: routes.MGUAYAQUIL,
  home: true,
  priority: 19,
  componentId: ComponentIdEnum.MGUAYAQUIL,
  requiredRol: RequiredRolEnum.MGUAYAQUIL,
};

const CONCILIATION_DASHBOARD: IKushkiMenu = {
  title: "Informes",
  icon: FormIcon,
  link: routes.REPORTS,
  home: true,
  priority: 14,
  componentId: ComponentIdEnum.CONCILIATION_DASHBOARD,
  requiredRol: RequiredRolEnum.CONCILIATION_DASHBOARD,
};
const GLOBAL_CONFIG: IKushkiMenu = {
  title: "Configuración Global",
  icon: SettingIcon,
  link: routes.GLOBAL_CONFIG,
  home: true,
  priority: 15,
  componentId: ComponentIdEnum.GLOBAL_CONFIG,
  requiredRol: RequiredRolEnum.GLOBAL_CONFIG,
};
const MERCHANT_CONFIG: IKushkiMenu = {
  title: "Configuración",
  icon: SettingIcon,
  link: routes.MERCHANT_CONFIG,
  home: true,
  priority: 16,
  componentId: ComponentIdEnum.MERCHANT_CONFIG,
  requiredRol: RequiredRolEnum.MERCHANT_CONFIG,
};

const DEVELOPER: IKushkiMenu = {
  title: "Desarrolladores",
  icon: DeveloperIcon,
  link: routes.DEVELOPER,
  home: true,
  priority: 17,
  componentId: ComponentIdEnum.DEVELOPER,
  requiredRol: RequiredRolEnum.DEVELOPER,
};

const COMPLIANCE_BETA: IKushkiMenu = {
  title: "Cumplimiento",
  icon: ComplianceIcon,
  link: routes.COMPLIANCE_BETA,
  home: true,
  priority: 20,
  list: [
    {
      priority: 1,
      title: "Configuración de alarmas",
      link: routes.COMPLIANCE_BETA,
      componentId: ComponentIdEnum.COMPLIANCE_CONFIG_ALARMAS,
      requiredRol: RequiredRolEnum.COMPLIANCE,
    },
    {
      priority: 2,
      title: "Alarmas activas",
      link: routes.COMPLIANCE_BETA_ACTIVE_ALARMS,
      componentId: ComponentIdEnum.COMPLIANCE_CONFIG_ALARMAS,
      requiredRol: RequiredRolEnum.COMPLIANCE,
    },
    ...(environment.complianceHistoric
      ? [
          {
            priority: 3,
            title: "Histórico de Alarmas",
            link: routes.COMPLIANCE_BETA_HISTORY_ALARMS,
            componentId: ComponentIdEnum.COMPLIANCE_HISTORIC,
            requiredRol: RequiredRolEnum.COMPLIANCE,
          },
        ]
      : []),
    {
      priority: 4,
      title: "Carga de ejecutivos",
      link: routes.COMPLIANCE_BETA_EXECUTIVES,
      componentId: ComponentIdEnum.COMPLIANCE_EXECUTIVES,
      requiredRol: RequiredRolEnum.COMPLIANCE,
    },
  ],
  componentId: ComponentIdEnum.COMPLIANCE,
  requiredRol: RequiredRolEnum.COMPLIANCE,
};

const CONCILIATION_TRANSACTIONAL: IKushkiMenu = {
  title: "Conciliación",
  icon: BillingIcon,
  link: routes.TRANSACTIONAL_CONCILIATION,
  home: true,
  priority: 11,
  list: [
    {
      priority: 1,
      title: "Transaccional",
      link: routes.TRANSACTIONAL_CONCILIATION,
      componentId: ComponentIdEnum.BANK_CONCILIATION_TRANSACCIONAL,
      requiredRol:
        RequiredRolEnum.CONCILIATION_TRANSACTIONAL_TRANSACTIONAL_CONCILIATION,
    },
  ],
  componentId: ComponentIdEnum.BANK_CONCILIATION_TRANSACCIONAL,
  requiredRol: RequiredRolEnum.CONCILIATION_TRANSACTIONAL,
};

const BANK_CONCILIATION: IKushkiMenu = {
  title: "Conciliación",
  icon: BillingIcon,
  link: routes.BANK_CONCILIATION_SUMMARY,
  home: true,
  list: [
    {
      priority: 1,
      title: "Bancaria",
      link: routes.BANK_CONCILIATION_SUMMARY,
      subListMenu: [
        {
          title: "Control de depósitos bancarios",
          link: routes.BANK_CONCILIATION_SUMMARY,
          componentId: null,
          requiredRol:
            RequiredRolEnum.BANK_CONCILIATION_BANK_CONCILIATION_SUMMARY_BANK_CONCILIATION_SUMMARY,
        },
        {
          title: "Conciliación bancaria",
          link: routes.BANK_CONCILIATION_TRANSACTIONS,
          componentId: null,
          requiredRol:
            RequiredRolEnum.BANK_CONCILIATION_BANK_CONCILIATION_SUMMARY_BANK_CONCILIATION_TRANSACTIONS,
        },
      ],
      componentId: ComponentIdEnum.BANK_CONCILIATION_BANK,
      requiredRol: RequiredRolEnum.BANK_CONCILIATION_BANK_CONCILIATION_SUMMARY,
    },
  ],
  priority: 11,
  componentId: ComponentIdEnum.BANK_CONCILIATION,
  requiredRol: RequiredRolEnum.BANK_CONCILIATION,
};

const REFUND_DASHBOARD_EXECUTOR: IKushkiMenu = {
  title: "Reembolsos",
  icon: BillingIcon,
  link: routes.REFUND_DASHBOARD,
  home: true,
  list: [
    {
      priority: 1,
      title: "Ejecutor",
      link: routes.REFUND_DASHBOARD_EXECUTOR,
      componentId: ComponentIdEnum.REFUNDS,
      requiredRol: RequiredRolEnum.REFUNDS,
    },
  ],
  priority: 11,
  componentId: ComponentIdEnum.REFUNDS,
  requiredRol: RequiredRolEnum.REFUNDS,
};

const REFUND_DASHBOARD_VALIDATOR: IKushkiMenu = {
  title: "Reembolsos",
  icon: BillingIcon,
  link: routes.REFUND_DASHBOARD,
  home: true,
  list: [
    {
      priority: 2,
      title: "Aprobador",
      link: routes.REFUND_DASHBOARD_VALIDATOR,
      componentId: ComponentIdEnum.REFUNDS,
      requiredRol: RequiredRolEnum.REFUNDS,
    },
  ],
  priority: 12,
  componentId: ComponentIdEnum.REFUNDS,
  requiredRol: RequiredRolEnum.REFUNDS,
};

const REFUND_DASHBOARD_VIEWER: IKushkiMenu = {
  title: "Reembolsos",
  icon: BillingIcon,
  link: routes.REFUND_DASHBOARD,
  home: true,
  list: [
    {
      priority: 3,
      title: "Solo vista",
      link: routes.REFUND_DASHBOARD_VIEWER,
      componentId: ComponentIdEnum.REFUNDS,
      requiredRol: RequiredRolEnum.REFUNDS,
    },
  ],
  priority: 13,
  componentId: ComponentIdEnum.REFUNDS,
  requiredRol: RequiredRolEnum.REFUNDS,
};

const CONTROL_ROLES_VIEWER: IKushkiMenu = {
  title: "Control de roles",
  icon: UserIcon,
  link: routes.CONTROL_ROLES_DASHBOARD,
  home: true,
  list: [],
  priority: 21,
};

const ROLES_CONTROL: IKushkiMenu = {
  title: "Control de roles",
  icon: ResellerIcon,
  link: routes.CONTROL_ROLES_DASHBOARD,
  home: true,
  priority: 1,
  componentId: ComponentIdEnum.ROLES_CONTROL,
  requiredRol: RequiredRolEnum.ROLES_CONTROL,
};

const MIGRATION: IKushkiMenu = {
  title: "Migración de comercios",
  icon: MerchantIcon,
  link: routes.MIGRATION,
  home: true,
  priority: 1,
  componentId: ComponentIdEnum.M_MERCHANT_MIGRATION,
  requiredRol: RequiredRolEnum.M_MERCHANT_MIGRATION,
};

const CLIENTS: IKushkiMenu = {
  title: "Clientes",
  icon: MerchantIcon,
  link: routes.CLIENTS,
  home: true,
  priority: 2,
  componentId: ComponentIdEnum.CLIENTS,
  requiredRol: RequiredRolEnum.CLIENTS,
};

const EXPRESS_CHECKOUT: IKushkiMenu = {
  title: "Express Checkout",
  icon: BoltIcon,
  link: routes.CONSOLE_EXPRESS_CHECKOUT,
  home: true,
  priority: 16,
  componentId: ComponentIdEnum.EXPRESS_CHECKOUT,
  requiredRol: RequiredRolEnum.EXPRESS_CHECKOUT,
};

export const MENU_CONSOLE_EXPRESS_CHECKOUT: IKushkiMenu[] = [
  TRANSACTIONS_CHARGES,
  EXPRESS_CHECKOUT,
];

const METRICS: IKushkiMenu = {
  title: "Métricas",
  icon: MerchantIcon,
  link: routes.DASHBOARD_METRICS,
  home: true,
  priority: 13.5,
  list: [
    {
      priority: 1,
      title: "Productos",
      link: routes.DASHBOARD_METRICS,
      componentId: ComponentIdEnum.DASHBOARD_METRICS,
      requiredRol: RequiredRolEnum.DASHBOARD_METRICS,
    },
  ],
  componentId: ComponentIdEnum.DASHBOARD_METRICS,
  requiredRol: RequiredRolEnum.DASHBOARD_METRICS,
};

const TRANSACTIONS_CHARGEBACK: IKushkiMenu = {
  title: "Transacciones",
  icon: TransactionIcon,
  link: routes.CONSOLE_TRANSACTIONS,
  home: true,
  priority: 2,
  list: [
    {
      priority: 1,
      title: "Contracargos",
      link: routes.CONSOLE_CHARGEBACK,
      componentId: ComponentIdEnum.TRANSACTIONS_TRANSACTIONS,
      requiredRol: RequiredRolEnum.TRANSACTIONS_TRANSACTIONS,
    },
  ],
  componentId: ComponentIdEnum.TRANSACTIONS,
  requiredRol: RequiredRolEnum.TRANSACTIONS,
};

export const MENU_BACKOFFICE_ADMIN: IKushkiMenu[] = [
  HOME,
  TRANSACTIONS,
  MERCHANTS,
  USERS,
  ALLIES,
  BILLING_DASHBOARD_MANUAL_CONTINGENCY,
  CONCILIATION_DASHBOARD,
  GLOBAL_CONFIG,
  REFUNDS_CONTROL,
  CLIENTS,
  MIGRATION,
  METRICS,
];

export const MENU_BACKOFFICE_RISK_MANAGER: IKushkiMenu[] = [
  HOME,
  MERCHANTS,
  TRANSACTIONS,
  FRAUD_PREVENTION,
  USERS,
];

export const MENU_BACKOFFICE_RISK_ANALYST: IKushkiMenu[] = [
  HOME,
  MERCHANTS,
  TRANSACTIONS,
  FRAUD_PREVENTION,
];

export const MENU_BACKOFFICE_USER_MASTER: IKushkiMenu[] = [
  TRANSACTIONS,
  MY_MERCHANTS,
  SUBSCRIPTIONS,
  USERS,
  CONCILIATION_DASHBOARD,
  MERCHANT_CONFIG,
  DEVELOPER,
  WALLET,
];

export const MENU_BACKOFFICE_MASTER_CUSTOMER: IKushkiMenu[] = [
  { ...MY_MERCHANTS, priority: 1 },
  TRANSACTIONS,
  USERS,
  DEVELOPER,
];

export const MENU_BACKOFFICE_MASTER_CREDENTIALS: IKushkiMenu[] = [DEVELOPER];

export const MENU_BACKOFFICE_USER_MAKER: IKushkiMenu[] = [USERS, SUBSCRIPTIONS];

export const MENU_BACKOFFICE_USER_READER: IKushkiMenu[] = [
  TRANSACTIONS,
  SUBSCRIPTIONS,
];

export const MENU_BACKOFFICE_MONITORING: IKushkiMenu[] = [
  HOME,
  MONITORING,
  TRANSACTIONS,
  MERCHANTS,
  COMPLIANCE_BETA,
];

export const MENU_WEBCHECKOUT_BASIC: IKushkiMenu[] = [SMARTLINKS];

export const MENU_BILLING_DASHBOARD_VALIDATOR: IKushkiMenu[] = [
  BILLING_DASHBOARD_VALIDATOR,
  GLOBAL_CONFIG,
];

export const MENU_BILLING_DASHBOARD_EXECUTOR: IKushkiMenu[] = [
  BILLING_DASHBOARD_EXECUTOR,
  GLOBAL_CONFIG,
  REFUND_DASHBOARD_VIEWER,
];

export const MENU_CORE_ACCOUNTING_DASHBOARD_EXECUTOR: IKushkiMenu[] = [
  CONCILIATION_DASHBOARD,
  BILLING_DASHBOARD_EXECUTOR,
  GLOBAL_CONFIG,
  REFUND_DASHBOARD_VIEWER,
];

export const MENU_CORE_ACCOUNTING_DASHBOARD_VALIDATOR: IKushkiMenu[] = [
  CONCILIATION_DASHBOARD,
  BILLING_DASHBOARD_VALIDATOR,
  BILLING_DASHBOARD_MANUAL_CONTINGENCY,
  GLOBAL_CONFIG,
  REFUND_DASHBOARD_VIEWER,
];

export const MENU_CONCILIATION_VALIDATOR: IKushkiMenu[] = [
  // CONCILIATION_VALIDATOR,
];

export const MENU_CONCILIATION_EXECUTOR: IKushkiMenu[] = [
  // CONCILIATION_EXECUTOR,
  REFUND_DASHBOARD_VIEWER,
];

export const MENU_CORE_ACCOUNTING_DASHBOARD_BOSS: IKushkiMenu[] = [
  CONCILIATION_TRANSACTIONAL,
];

export const MENU_CORE_ACCOUNTING_DASHBOARD_ANALYST: IKushkiMenu[] = [
  CONCILIATION_TRANSACTIONAL,
];

export const MENU_BANK_CONCILIATION: IKushkiMenu[] = [BANK_CONCILIATION];

export const MENU_GLOBAL_REPORTS: IKushkiMenu[] = [CONCILIATION_DASHBOARD];

export const MENU_WEBCHECKOUT_MASTER: IKushkiMenu[] = [SMARTLINKS];

export const MENU_BACKOFFICE_BANK: IKushkiMenu[] = [HOME];

export const MENU_SNR: IKushkiMenu[] = [SNR, VEHISCORE, REFUNDS_CONTROL];

export const MENU_MGUAYAQUIL: IKushkiMenu[] = [MGUAYAQUIL];

export const MENU_BACKOFFICE_RESELLER: IKushkiMenu[] = [];

export const MENU_BACKOFFICE_COMPLIANCE: IKushkiMenu[] = [
  COMPLIANCE_MERCHANTS,
  TRANSACTIONS,
  COMPLIANCE_BETA,
];

export const MENU_BACKOFFICE_USER_PARENT: IKushkiMenu[] = [
  HOME,
  TRANSACTIONS,
  USERS,
  CONCILIATION_DASHBOARD,
  MERCHANT_CONFIG,
];

export const MENU_BACKOFFICE_AZURE: IKushkiMenu[] = [
  HOME,
  FRAUD_PREVENTION,
  MIGRATION,
  MERCHANTS,
  COMPLIANCE_MERCHANTS,
  TRANSACTIONS,
  MY_MERCHANTS,
  MONITORING,
  USERS,
  ALLIES,
  SUBSCRIPTIONS,
  SMARTLINKS,
  BILLING_DASHBOARD_EXECUTOR,
  BILLING_DASHBOARD_VALIDATOR,
  VEHISCORE,
  SNR,
  REFUNDS_CONTROL,
  MGUAYAQUIL,
  CONCILIATION_DASHBOARD,
  GLOBAL_CONFIG,
  MERCHANT_CONFIG,
  DEVELOPER,
  COMPLIANCE_BETA,
  CONCILIATION_TRANSACTIONAL,
  BANK_CONCILIATION,
  ROLES_CONTROL,
  CLIENTS,
  REFUND_DASHBOARD_VALIDATOR,
  REFUND_DASHBOARD_EXECUTOR,
  REFUND_DASHBOARD_VIEWER,
  METRICS,
];

export const MENU_REFUND_DASHBOARD_VALIDATOR: IKushkiMenu[] = [
  REFUND_DASHBOARD_VALIDATOR,
];

export const MENU_REFUND_DASHBOARD_EXECUTOR: IKushkiMenu[] = [
  REFUND_DASHBOARD_EXECUTOR,
];

export const MENU_CHARGEBACK_MANAGER: IKushkiMenu[] = [TRANSACTIONS_CHARGEBACK];
