import React, { useEffect, useState } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import {
  validateCardPaths,
  validateStartWith,
} from "../infrastructure/constants/CatalogLayout";

const borderBox = "border-box" as const;
const styles = (theme: Theme) => ({
  mainContent: {
    flexGrow: 1,
    padding: theme.spacing(3),
    boxSizing: borderBox,
    overflow: "auto",
    backgroundColor: theme.palette.background.default,
    marginTop: 80,
  },
  mainContentToWebcheckout: {
    flexGrow: 1,
    boxSizing: borderBox,
    overflow: "auto",
    backgroundColor: theme.palette.background.default,
    marginTop: 70,
  },
  mainContentMobile: {
    flexGrow: 1,
    padding: theme.spacing(3),
    boxSizing: borderBox,
    overflow: "auto",
    backgroundColor: theme.palette.background.default,
    marginBottom: 70,
    marginTop: 70,
  },
  mainContentLayout: {
    flexGrow: 1,
    boxSizing: borderBox,
    overflow: "auto",
    backgroundColor: theme.palette.background.default,
    padding: 24,
  },
});

const MainContent: React.FC = () => {
  const theme = useTheme();
  const inlineStyles = styles(theme);
  const isMobile: boolean = useMediaQuery("(max-width:767px)");
  const [webcheckout, setWebcheckout] = useState<boolean>(false);
  const [layout, setLayout] = useState<boolean>(false);

  useEffect(() => {
    if (
      window.location.pathname.startsWith("/webcheckout") ||
      window.location.pathname.startsWith("/kform/")
    )
      setWebcheckout(true);
    else setWebcheckout(false);

    if (
      validateStartWith(window.location.pathname) ||
      validateCardPaths(window.location.pathname)
    )
      setLayout(true);
  }, [window.location.pathname]);

  return (
    <main
      style={
        isMobile
          ? inlineStyles.mainContentMobile
          : webcheckout
          ? inlineStyles.mainContentToWebcheckout
          : layout
          ? inlineStyles.mainContentLayout
          : inlineStyles.mainContent
      }
    >
      <div id="single-spa-application:spa-backoffice-user"></div>
      <div id="single-spa-application:spa-backoffice-reseller"></div>
      <div id="single-spa-application:spa-backoffice-billing-dashboard"></div>
      <div id="single-spa-application:spa-backoffice-transaction"></div>
      <div id="single-spa-application:spa-backoffice-dashboard"></div>
      <div id="single-spa-application:spa-backoffice-dispersion"></div>
      <div id="single-spa-application:spa-backoffice-deferred"></div>
      <div id="single-spa-application:spa-backoffice-smartlink"></div>
      <div id="single-spa-application:spa-backoffice-merchant"></div>
      <div id="single-spa-application:spa-backoffice-wallet"></div>
      <div id="single-spa-application:spa-backoffice-checkout"></div>
      <div id="single-spa-application:spa-backoffice-subscription-legacy"></div>
      <div id="single-spa-application:spa-backoffice-snr"></div>
      <div id="single-spa-application:spa-backoffice-conciliation"></div>
      <div id="single-spa-application:spa-backoffice-mguayaquil"></div>
      <div id="single-spa-application:spa-backoffice-merchant-list"></div>
      <div id="single-spa-application:spa-backoffice-global-configuration"></div>
      <div id="single-spa-application:spa-backoffice-costs"></div>
      <div id="single-spa-application:spa-backoffice-transaction-rates"></div>
      <div id="single-spa-application:spa-backoffice-merchant-configuration"></div>
      <div id="single-spa-application:spa-backoffice-my-merchant"></div>
      <div id="single-spa-application:spa-backoffice-processor-list"></div>
      <div id="single-spa-application:spa-backoffice-merchant-properties"></div>
      <div id="single-spa-application:spa-backoffice-support"></div>
      <div id="single-spa-application:spa-backoffice-subscription"></div>
      <div id="single-spa-application:spa-backoffice-global-configuration"></div>
      <div id="single-spa-application:spa-backoffice-etpay-customization"></div>
      <div id="single-spa-application:spa-backoffice-webhook"></div>
      <div id="single-spa-application:spa-console-merchant-webhook"></div>
      <div id="single-spa-application:spa-backoffice-webcheckout"></div>
      <div id="single-spa-application:spa-backoffice-billing-merchant" />
      <div id="single-spa-application:spa-backoffice-credentials"></div>
      <div id="single-spa-application:spa-backoffice-holidays"></div>
      <div id="single-spa-application:spa-backoffice-card-processor"></div>
      <div id="single-spa-application:spa-backoffice-payouts-cash-processor"></div>
      <div id="single-spa-application:spa-backoffice-payouts-transfer-processor"></div>
      <div id="single-spa-application:spa-backoffice-transfer-subscriptions-processor"></div>
      <div id="single-spa-application:spa-backoffice-transfer-processor"></div>
      <div id="single-spa-application:spa-backoffice-cash-processor"></div>
      <div id="single-spa-application:spa-backoffice-chargeback"></div>
      <div id="single-spa-application:spa-console-chargeback"></div>
      <div id="single-spa-application:spa-backoffice-rules"></div>
      <div id="single-spa-application:spa-backoffice-compliance"></div>
      <div id="single-spa-application:spa-backoffice-monitoring"></div>
      <div id="single-spa-application:spa-backoffice-create-merchant" />
      <div id="single-spa-application:spa-backoffice-kform"></div>
      <div id="single-spa-application:spa-backoffice-kforms-list"></div>
      <div id="single-spa-application:spa-backoffice-retentions"></div>
      <div id="single-spa-application:spa-backoffice-payouts-transaction"></div>
      <div id="single-spa-application:spa-backoffice-merchant-deferred" />
      <div id="single-spa-application:spa-backoffice-rule-request" />
      <div id="single-spa-application:spa-backoffice-rule-request-manager" />
      <div id="single-spa-application:spa-backoffice-vehiscore" />
      <div id="single-spa-application:spa-backoffice-b2c-refunds" />
      <div id="single-spa-application:spa-backoffice-merchant-people" />
      <div id="single-spa-application:spa-backoffice-bank-conciliation" />
      <div id="single-spa-application:spa-backoffice-retry-rules"></div>
      <div id="single-spa-application:spa-backoffice-transactional-conciliation" />
      <div id="single-spa-application:spa-console-create-node" />
      <div id="single-spa-application:spa-console-merchant-company-data" />
      <div id="single-spa-application:spa-console-financial-configuration" />
      <div id="single-spa-application:spa-backoffice-login-internal" />
      <div id="single-spa-application:spa-backoffice-roles-control" />
      <div id="single-spa-application:spa-backoffice-refund-dashboard" />
      <div id="single-spa-application:spa-backoffice-config-by-file"></div>
      <div id="single-spa-application:spa-backoffice-wallet-dashboard" />
      <div id="single-spa-application:spa-backoffice-merchant-resume"></div>
      <div id="single-spa-application:spa-console-centralization"></div>
      <div id="single-spa-application:spa-console-merchant-preferences"></div>
      <div id="single-spa-application:spa-console-credentials" />
      <div id="single-spa-application:spa-backoffice-payouts-dispersion"></div>
      <div id="single-spa-application:spa-console-developers"></div>
      <div id="single-spa-application:spa-console-service-config"></div>
      <div id="single-spa-application:spa-console-clients"></div>
      <div id="single-spa-application:spa-console-wallet"></div>
      <div id="single-spa-application:spa-console-wallet-dashboard"></div>
      <div id="single-spa-application:spa-console-transaction-rates"></div>
      <div id="single-spa-application:spa-backoffice-migration"></div>
      <div id="single-spa-application:spa-console-processing-config"></div>
      <div id="single-spa-application:spa-console-payout-business-rules"></div>
      <div id="single-spa-application:spa-console-business-rules"></div>
      <div id="single-spa-application:spa-backoffice-checkout-v2" />
      <div id="single-spa-application:spa-console-user"></div>
      <div id="single-spa-application:spa-console-my-merchant" />
      <div id="single-spa-application:spa-console-transaction" />
      <div id="single-spa-application:spa-console-merchant-configuration" />
      <div id="single-spa-application:spa-console-payouts-transaction" />
      <div id="single-spa-application:spa-console-compliance" />
      <div id="single-spa-application:spa-console-express-checkout"></div>
      <div id="single-spa-application:spa-backoffice-dashboard-metrics"></div>
      <div id="single-spa-application:spa-console-payouts-card-processor"></div>
    </main>
  );
};

export default MainContent;
