import { defaultTo, get } from "lodash";
import { ISessionSupport } from "../infrastructure/interfaces/ISessionSupport";

export class SessionService {
  public static setSessionSupport(sessionSupport: ISessionSupport | undefined) {
    const cache = JSON.parse(defaultTo(localStorage.getItem("cache"), "{}"));
    const _cache = { ...cache, sessionSupport };

    localStorage.setItem("cache", JSON.stringify(_cache));
  }

  public static getSessionSupport() {
    const cache = JSON.parse(defaultTo(localStorage.getItem("cache"), "{}"));
    return get(cache, "sessionSupport", undefined);
  }

  public static removeSessionSupport() {
    this.setSessionSupport(undefined);
  }

  public static includeMerchant(includedMerchantId: string[]): boolean {
    const merchant_id: string = defaultTo(
      localStorage.getItem("merchantId"),
      ""
    );
    return includedMerchantId.includes(merchant_id);
  }
}
