import React from "react";
import "./App.css";
import Navigation from "./containers/Nav";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { reducer } from "./store/reducer";
import { applyMiddleware, compose, createStore, Store } from "redux";

const composeEnhancers = process.env.REACT_APP_DEV_TOOLS
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;

const store: Store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunk))
);

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Navigation />
    </Provider>
  );
};

export default App;
