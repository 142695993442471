export enum ComponentIdEnum {
  HOME = "M_HOME",
  FRAUD_PREVENTION = "M_FRAUD_PREVENTION",
  MERCHANTS = "M_MERCHANTS",
  COMPLIANCE_MERCHANTS = "M_COMPLIANCE_MERCHANTS",
  TRANSACTIONS = "M_TRANSACTIONS",
  TRANSACTIONS_TRANSACTIONS = "M_TRANSACTIONS.TRANSACTIONS",
  TRANSACTIONS_PAYOUTS_TRANSACTION = "M_TRANSACTIONS.PAYOUTS_TRANSACTION",
  TRANSACTIONS_CHARGEBACK = "M_TRANSACTIONS.CHARGEBACK",
  MY_MERCHANTS = "M_MY_MERCHANTS",
  MONITORING = "M_MONITORING",
  USERS = "M_USERS",
  ALLIES = "M_ALLIES",
  SUBSCRIPTIONS = "M_SUBSCRIPTIONS",
  SMARTLINKS = "M_SMARTLINKS",
  BILLING = "M_BILLING",
  BILLING_DASHBOARD_EXECUTOR_EXECUTOR = "M_BILLING.EXECUTOR",
  BILLING_DASHBOARD_VALIDATOR_VALIDATOR = "M_BILLING.VALIDATOR",
  SNR = "M_SNR",
  VEHISCORE = "M_VEHISCORE",
  REFUNDS_CONTROL = "M_REFUNDS_CONTROL",
  MGUAYAQUIL = "M_MGUAYAQUIL",
  CONCILIATION_DASHBOARD = "M_REPORTS",
  RETRY_RULES = "M_RETRY_RULES",
  GLOBAL_CONFIG = "M_GLOBAL_CONFIG",
  MERCHANT_CONFIG = "M_MERCHANT_CONFIG",
  DEVELOPER = "M_DEVELOPER",
  COMPLIANCE = "M_COMPLIANCE",
  COMPLIANCE_COMPLIANCE_ALARMS = "M_COMPLIANCE.COMPLIANCE_ALARMS",
  COMPLIANCE_CONFIG_ALARMAS = "M_COMPLIANCE.COMPLIANCE_CONFIG_ALARMAS",
  COMPLIANCE_HISTORIC = "M_COMPLIANCE.HISTORIC",
  COMPLIANCE_EXECUTIVES = "M_COMPLIANCE.EXECUTIVES",
  COMPLIANCE_COMPLIANCE_EXECUTIVES = "M_COMPLIANCE.COMPLIANCE_EXECUTIVES",
  SUPPORT = "M_SUPPORT",
  BANK_CONCILIATION = "M_BANK_CONCILIATION",
  BANK_CONCILIATION_TRANSACCIONAL = "M_BANK_CONCILIATION.TRANSACTIONAL",
  BANK_CONCILIATION_BANK = "M_BANK_CONCILIATION.BANKING",
  ROLES_CONTROL = "M_ROLESCONTROL",
  CLIENTS = "M_CLIENTS",
  REFUNDS = "M_REFUNDS",
  EXPRESS_CHECKOUT = "M_EXPRESS_CHECKOUT",
  M_MERCHANT_MIGRATION = "M_MERCHANT_MIGRATION",
  DASHBOARD_METRICS = "M_DASHBOARD_METRICS",
  DASHBOARD_METRICS_PRODUCTS = "M_DASHBOARD_METRICS.PRODUCT_METRICS",
}
